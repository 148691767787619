/**
 * Extend the dw object
 */
dw.cookie = {}

/**
 * Gets a cookie by name
 *
 * @param name [string] - Name of the cookie
 *
 * @return [string|null] - Cookie value or null if the
 * cookie doesn't exists.
 */
dw.cookie.get = function(name) {

	// Update name variable
	name = name + '=';

	// Decode cookie string
	var decodedCookie = decodeURIComponent(document.cookie);

	// Split cookie string
	var cookieArray = decodedCookie.split(';');

	// Loop throug each part of the cookie string
	for (var i = 0; i < cookieArray.length; i++) {

		// Get current part
		var part = cookieArray[i];

		// Prepare part
		while (part.charAt(0) == ' ') part = part.substring(1);

		// Return value if the cookie exists
		if (part.indexOf(name) == 0) return part.substring(name.length, part.length);

	}

	// Cookie doesn't exists
	return null;

}

/**
 * Sets a cookie
 *
 * @param name [string] - Name of the cookie
 * @param value [number|string] - Value of the cookie
 * @param hours [number] - Expiration in hours
 *
 * @return [undefined]
 */
dw.cookie.set = function(name, value, hours) {

	// Create new date object
	var d = new Date();

	// Set defined number of hours ahead
	d.setTime(d.getTime() + (3600000 * hours));

	// Prepare the expiration part of the cookie
	var expires = 'expires=' + d.toUTCString();

	// Set the cookie
	document.cookie = name + '=' + value + '; ' + expires + '; path=/';

}

/**
 * Removes a cookie by name
 *
 * @return [undefined]
 */
dw.cookie.remove = function(name) {

	// Remove the cookie
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';

}
