// Define scroll events
dw.scrollEvents = function() {

	/* SCROLL EVENTS */

}

// Define resize events
dw.resizeEvents = function() {

	/* RESIZE EVENTS */

	// Trigger scroll events
	dw.scrollEvents();

}

// Add 'preloader-done' event listener
window.addEventListener('preloader-done', function() {

	// Trigger resize events
	dw.resizeEvents();

});

// Add scroll event listeners
window.addEventListener('scroll', dw.scrollEvents);
window.addEventListener('touchmove', dw.scrollEvents);

// Add resize event listener
window.addEventListener('resize', dw.resizeEvents);
