jQuery( document ).ready(function() {


  jQuery("#accordion").accordion({
    animate: {
        duration: 50
    }
});


  jQuery( "#angebot-left" ).mouseover(function() {
    jQuery('.steuerrad').addClass('turnleft');
  });

  jQuery( "#angebot-left" ).mouseout(function() {
    jQuery('.steuerrad').removeClass('turnleft');
  });

  jQuery( "#angebot-right" ).mouseover(function() {
    jQuery('.steuerrad').addClass('turnright');
  });

  jQuery( "#angebot-right" ).mouseout(function() {
    jQuery('.steuerrad').removeClass('turnright');
  });

  jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip()
  })




});
