/**
 * This object contains helpers (variables and functions)
 * which can be reused in many website projects.
 *
 * Extend this object by writing a {module}.js file in the
 * res/js/dw directory.
 *
 * Targeted browser compatibility: Chrome, Firefox, IE10+, Safari 8+
 */
var dw = {}

/**
 * Returns the current scroll position
 *
 * @return [number]
 */
dw.scroll = function() {

	// Return current scroll position
	return (typeof window.scrollY === 'undefined') ? window.pageYOffset : window.scrollY;

}

/**
 * Gets the support transition end event
 *
 * @return [string|null] - The transition end event
 * or null if it is not supported by the browser
 */
dw.transitionEnd = (function() {

	// Possible transition end events
	var transitions = {
		'transition': 'transitionend',
		'OTransition': 'oTransitionEnd',
		'MozTransition': 'transitionend',
		'WebkitTransition': 'webkitTransitionEnd'
	}

	// Loop through the possible events
	for (var t in transitions) {

		// Check if the event is defined
		if (document.body.style[t] !== undefined) {

			// Return
			return transitions[t];

		}

	}

	// None is supported by the browser
	return null;

})();

/**
 * Adds a BEM modifier class ({class}--{modifier}) to a
 * specified element.
 *
 * @param element [object] - A NodeList object whose first
 * class name in the class="" attribute will be used as the
 * base {class} for the BEM structure.
 *
 * @param modifier [string] - BEM modifier
 *
 * @return [undefined]
 */
dw.add = function(element, modifier) {

	// Check if element has at least one class
	if (!element || element.classList.length < 1) return;

	// Get first class of the element
	var base = element.classList[0];

	// Add modifier class
	element.classList.add(base + '--' + modifier);

}

/**
 * Removes a BEM modifier class ({class}--{modifier}) from
 * a specified element.
 *
 * @param element [object] - A NodeList object whose first
 * class name in the class="" attribute will be used as the
 * base {class} for the BEM structure.
 *
 * @param modifier [string] - BEM modifier
 *
 * @return [undefined]
 */
dw.remove = function(element, modifier) {

	// Check if element has at least one class
	if (!element || element.classList.length < 1) return;

	// Get first class of the element
	var base = element.classList[0];

	// Add modifier class
	element.classList.remove(base + '--' + modifier);

}

/**
 * Toggles a BEM modifier class ({class}--{modifier}) from
 * a specified element.
 *
 * @param element [object] - A NodeList object whose first
 * class name in the class="" attribute will be used as the
 * base {class} for the BEM structure.
 *
 * @param modifier [string] - BEM modifier
 *
 * @return [undefined]
 */
dw.toggle = function(element, modifier) {

	// Check if element has at least one class
	if (!element || element.classList.length < 1) return;

	// Get first class of the element
	var base = element.classList[0];

	// Add modifier class
	element.classList.toggle(base + '--' + modifier);

}

/**
 * Checks if a BEM modifier class ({class}--{modifier})
 * exists in a specified element.
 *
 * @param element [object] - A NodeList object whose first
 * class name in the class="" attribute will be used as the
 * base {class} for the BEM structure.
 *
 * @param modifier [string] - BEM modifier
 *
 * @return [bool] - True if the modifier exists and false if not
 */
dw.contains = function(element, modifier) {

	// Check if element has at least one class
	if (!element || element.classList.length < 1) return false;

	// Get first class of the element
	var base = element.classList[0];

	// Return wether the modifier exists or not
	return element.classList.contains(base + '--' + modifier);

}
