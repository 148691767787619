/**
 * Scroll animation frame variable used for requestAnimationFrame()
 */
dw.scrollAnimationFrame = null;

/**
 * Cancels the scroll animation
 */
dw.cancelScrollAnimation = function() { cancelAnimationFrame(dw.scrollAnimationFrame) }

/**
 * This function makes the site scroll to a defined element.
 * It also includes animation cancel when the user tries to
 * scroll the page manually (via mouse or touchscreen).
 *
 * @param element [number|string|object] - Top value, CSS selector
 * or a destination element (calculated from top).
 *
 * @param duration [number] - Scroll duration in milliseconds
 *
 * @param offset [number] (optional) - Top offset in pixels, which
 * is added to the element's top value.
 *
 * @return [undefined]
 */
dw.scrollTo = function(element, duration, offset) {

	// Get destination element from a given string selector
	if (typeof element === 'string') element = document.querySelector(element);

	// Set default offset value if it's not provided or not a number
	offset = (typeof offset !== 'number') ? 0 : offset;

	// Cancel all current scroll animations
	dw.cancelScrollAnimation();

	// Define starting scroll position
	var scrollStart = dw.scroll();

	// If the element is actually a numeric value
	if (typeof element === 'number') {

		// Define top value
		var top = element;

	} else {

		// Get top margin of destination element
		var marginTop = parseInt(window.getComputedStyle(element, null).marginTop.replace('px', ''));

		// Define destination variables
		var top = Math.round(element.getBoundingClientRect().top - marginTop) + offset;

	}

	// Define scroll end
	var scrollEnd = scrollStart + top;

	// Declare initial time variable
	var initTime = null;

	// Render function
	function render(timestamp) {

		// Set initial time
		if (!initTime) initTime = timestamp;

		// Calculate the delta
		var t = Math.min((timestamp - initTime) / duration, 1);

		// Accelerate until halfway, then decelerate
		t = t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

		// Call the actual scroll function
		window.scrollTo(0, scrollStart + top * t);

		// Loop until current scroll reaches its final position
		if (dw.scroll() != scrollEnd) dw.scrollAnimationFrame = requestAnimationFrame(render);

	}

	// Trigger render loop
	if (scrollStart != scrollEnd) dw.scrollAnimationFrame = requestAnimationFrame(render);

}

/**
 * Add event listeners which cancels the scroll animation
 */
window.addEventListener('mousewheel', dw.cancelScrollAnimation);
window.addEventListener('DOMMouseScroll', dw.cancelScrollAnimation);
window.addEventListener('touchmove', dw.cancelScrollAnimation);
window.addEventListener('touchstart', dw.cancelScrollAnimation);
