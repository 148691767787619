// Extend the dw object
dw.example = {
	element: document.querySelectorAll('.element')
}

jQuery(document).ready(function() {

	jQuery( ".hamburger" ).click(function() {
  	jQuery('.hamburger').toggleClass('is-active');
		jQuery('#mobile-nav').slideToggle('slow');

	});

	jQuery( function() {
    jQuery( "#accordion" ).accordion();
  } );

});

// Do stuff
dw.example.dummy = function() {

	// Dummy console log
	console.log('dw');

}
